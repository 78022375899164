<template>
  <div
    class="shadow-md rounded-md lg:px-20 px-5 lg:py-14 py-10 bg-white w-full"
    :style="{'max-width': '650px'}"
  >
    <div
      class="flex lg:flex-row flex-col lg:justify-between justify-center lg:items-end items-center"
    >
      <p
        class="lg:text-4xl text-3xl text-ash-800 lg:mr-7 mr-0 mb-2"
      >
        會員免費下載
      </p>
      <div>
        <div
          class="flex items-center lg:pb-3 pb-0"
        >
          <p
            class="text-base text-ash-600"
          >
            沒有帳號？
          </p>
          <router-link
            :to="{
              name: 'Signup'
            }"
            class="flex items-center"
          >
            <p
              class="text-base text-ash-600"
            >立即註冊</p>
            <DgIcon
              size="7"
              name="chevron-right"
              color="ash-600"
              class="ml-3"
            />
          </router-link>
        </div>
      </div>
    </div>
    <p class="my-5">
      您是就享知會員嗎？請登入就享知帳號密碼 <br>
      即可存取鼎新官網【資料下載】專區所有資源！
    </p>
    <DgForm
      ref="form"
      class="my-10"
      :settings="settings"
    />
    <!-- <div
      class="flex lg:flex-row flex-col justify-between items-center my-10"
    >
      <p
      class="text-sm text-ash-600"
      >或使用社群帳號登入</p>
      <DgSeparator
        size="143"
        class="lg:block hidden"
      />
    </div> -->
    <button
      class="gtm-login-btn bg-primary-gradient-to-r py-2 text-lg text-white rounded-full w-full"
      @click="methodLogin"
    >
      登入
    </button>
    <div
      class="mt-9 flex justify-center items-center"
    >
      <router-link
        :to="{
          name: 'Forgot'
        }"
        class="gtm-login-link"
      >
        <span
          class="text-sm text-ash-600 text-center hover:text-primary"
        >忘記密碼？</span>
      </router-link>
      <span
        class="text-ash-600 px-4"
      >|</span>
      <router-link
        :to="{
          name: 'Home'
        }"
        class="gtm-login-link"
      >
        <span
          class="text-sm text-ash-600 text-center hover:text-primary"
        >返回下載列表</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import DgIcon from '@/components/base/DgIcon.vue';
// import DgSeparator from '@/components/base/DgSeparator.vue';
import DgForm from '@/components/dgForm/Index.vue';
import { required } from '@/utils/validator';
import requestApi from '@/lib/http';

export default {
  name: 'LoginCard',
  components: {
    DgIcon,
    // DgSeparator,
    DgForm,
  },
  data() {
    return {
      loginThirdList: {
        facebook: '',
        line: '',
        google: '',
      },
      settings: [
        {
          componentType: 'text',
          col: '2',
          uniKey: 'username',
          placeholder: '請輸入 email 或手機',
          // defaultValue: 'admin',
          rules: [
            required,
          ],
        },
        {
          componentType: 'text',
          col: '2',
          inputType: 'password',
          uniKey: 'password',
          placeholder: '請輸入8~12位之密碼',
          // defaultValue: 'qwerqwer',
          rules: [
            required,
            // TODO: rm
            // min(8),
            // max(12),
          ],
        },
      ],
    };
  },
  created() {
    // console.info('👉👉 this.$route', this.$route);
  },
  methods: {
    ...mapActions({
      actionHandleLoginInfo: 'handleLoginInfo',
    }),
    ...mapMutations({
      showNotifySuccess: 'setNotifySuccess',
      showNotifyError: 'setNotifyError',
    }),
    async methodLogin() {
      const params = this.$refs.form.methodGetContent();
      if (!params) {
        this.showNotifyError('登入失敗，請檢查錯誤提示');
        return;
      }

      const { status, message, result } = await requestApi('login', params);

      if (status) {
        const { token = false } = result?.data ?? {};
        const isSetup = await this.actionHandleLoginInfo({ token });
        if (isSetup) {
          this.methodGo({
            name: 'Home',
            query: this.$route.params,
          });
        }
        this.showNotifySuccess('登入成功');
      } else {
        this.showNotifyError(`登入失敗，${message}`);
      }
    },
    methodGo(route) {
      this.$router.push(route);
    },
  },
};
</script>
