<template>
  <div
    class="bg-primary bg-opacity-10 flex items-center justify-items-center"
  >
    <div
      class="max-w-1500 mx-auto px-5 py-5"
    >
      <!-- left -->
      <div class="pb-3">
        <DgImg
          src="logo/imgLogo"
          :style="{width: '200px'}"
        />
      </div>
      <LoginCard />

    </div>
  </div>
</template>

<script>
// import DgImg from '@/components/base/DgImg.vue';
import LoginCard from '@/views/login/LoginCard.vue';
import DgImg from '../../components/base/DgImg.vue';

export default {
  name: 'Login',
  components: {
    // DgImg,
    LoginCard,
    DgImg,
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
